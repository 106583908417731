.newField {
  background-color: #eee;
  animation: show_animation 400ms 1;
  animation-delay: 100ms;
  animation-fill-mode: forwards;
  opacity: 0;
  transition: all 0.4s ease-out;
}

@keyframes  show_animation {
  0%   {
    height: 0;
    opacity: 0;
    margin-left: -100px;
  }
  100% {
    height: 64px;
    opacity: 1;
    margin-left: 0px;
  }
}

.enter,
.enterActive,
.enterActiveDone{
  background: blue;
}

.appear,
.appearActive {
  transition: all 400ms ease-out;
  background: red;
}

.exit,
.exitActive,
.exitDone {
  background: yellow;
}