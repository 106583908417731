.navigation {
  padding: 16px;
  border-bottom: 1px solid #e2e2e2;
}

@mixin navLinkStyle {
   color: #333;
   text-decoration: none;
}

.navLink {
 @include navLinkStyle;
  &:hover {
    color: #337fd0;
  }
}

.navLinkActive {
  text-decoration: underline;
}

a + a {
  margin-left: 16px;
}
