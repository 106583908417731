@import '../../../scss/sizes';
@import '../../../scss/colors';

.searchItem {
  border-bottom: 1px solid $COLOR-BORDER;
  display: flex;
  justify-content: space-between;
  margin-bottom: $size-3;
  padding: $size-3 0;
}

.searchItemActions {
  display: flex;
}
