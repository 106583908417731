div.textfield {
  display: block;
}

.buttonGoogle {
  svg {
    margin-right: 8px;
  }
}

.loggedIn {
  display: flex;
  align-items: center;
}

.signout {
  margin-top: 8px;
}

.mustLogin {
  color: #e40043;
}

.googlePhoto {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;
}