.ui-button {

}
button.ui-button--icon {
  width: 36px;
  height: 36px;
  min-width: 36px;
}

.bordered {
  border: 1px solid #e7e7e7;
}