.TextField {
  margin-top: 16px;
}

.ButtonAddTag {
  margin-top: 16px;
}

.ChipsContainer {
  display: flex;
  margin: 16px 0;
}

.Chip + .Chip {
  margin-left: 16px;
}
