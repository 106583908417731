@import '../../scss/colors';
@import '../../scss/sizes';

.header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid $COLOR-DARK-BORDER;
  margin-bottom: $size-3;
}
