@import 'scss/sizes';

.charactersManager {
  margin: 0 auto;
  //max-width: 1440px;
}

.overviewContainer {
  padding: $size-3;
}

.loginForm {
  padding: $size-3;
}
