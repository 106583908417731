.ChapterForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .TextField + .TextField {
    margin-top: 16px;
  }

  .TagsHolder {
    margin-top: 16px;
  }
}

.formActions {
  margin-top: 16px;
  display: flex;

  button + button {
    margin-left: 8px;
  }
}
