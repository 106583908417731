@import '../../scss/colors';
@import '../../scss/sizes';

.view {
  display: flex;
  align-items: center;
  margin: $size-3 0;
  padding: $size-2 0;
}

div.field,
fieldWithButton {
  width: 150px;
}

div.field {
  margin: 0 $size-1 0 0;
}

.fieldContainer {
  margin: 0 $size-2 0 0;
}

.fieldWithButton {
  display: flex;
  align-items: center;
}

.audioButtons{
  display: flex;
  button {
    margin-left: $size-3
  }
}

.itemActions {
  margin-left: auto;
  button {
    margin-left: $size-3;
  }
}