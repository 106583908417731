@import './scss/util';

* {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: hsl(0, 0%, 95%);
}
