div.error {
  background-color: #870029;
}

.container {
  transition: all 0.4s ease-out;
}

div.ok {
  background: forestgreen;
}